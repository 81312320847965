<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-10 col-12 mb-2">

                <div class="breadcrumb-wrapper">
                    <h4 class="content-header-title float-left pr-1 mb-0">
                        <router-link to="/asb_kewajaran_belanja/list_dinas">
                            <div type="button" class="btn btn-primary btn-sm">
                                <feather-icon icon="GridIcon" size="20" class="" />
                            </div>

                            {{ dinas.nama_dinas }}

                        </router-link>
                    </h4>

                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link to="/asb_kewajaran_belanja/detail_dinas/dashboard">Home
                            </router-link>
                        </li>
                    </ol>
                </div>


            </div>
            <div class="content-header-right text-md-right col-md-2 col-12 d-md-block d-none">

                <button type="button" class="btn btn-primary btn-block waves-effect waves-float waves-light"
                    v-b-modal.modal-lg variant="outline-primary"><i class="fa fa-plus mr-1"></i>Sub Kegiatan </button>

            </div>


        </div>

        <div class="row">
            <div class="col-xl-6 col-md-6 col-6">
                <div class="card card-statistics">
                    <div class="card-header">
                        <h4 class="card-title">ASB FISIK</h4>
                    </div>

                    <div class="card-body  ">
                        <div class="row">
                            <div class="col-xl-6 col-sm-6 col-12   mb-xl-0">
                                <router-link to="/asb_kewajaran_belanja/detail_dinas/asb">
                                    <div class="media" @click="status_asb('Fisik', 'Wajar')">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <feather-icon icon="GridIcon" size="18" class="" />
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ count_data.fisik_wajar }}</h4>
                                            <p class="card-text font-small-3 mb-0">Sub Kegiatan Wajar</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-xl-6 col-sm-6 col-12   mb-xl-0">
                                <router-link to="/asb_kewajaran_belanja/detail_dinas/asb">
                                    <div class="media" @click="status_asb('Fisik','Tidak Wajar')">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <feather-icon icon="GridIcon" size="18" class="" />
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder text-danger mb-0">
                                                {{ count_data.fisik_tidak_wajar }}</h4>
                                            <p class="card-text text-danger font-small-3 mb-0">Sub Kegiatan Tidak Wajar
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-6">
                <div class="card card-statistics">
                    <div class="card-header">
                        <h4 class="card-title">ASB NON FISIK 
                        </h4>
                    </div>

                    <div class="card-body  ">
                        <div class="row">
                            <div class="col-xl-6 col-sm-6 col-12   mb-xl-0">
                                <router-link to="/asb_kewajaran_belanja/detail_dinas/asb">
                                    <div class="media" @click="status_asb('Non Fisik', 'Wajar')">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <feather-icon icon="GridIcon" size="18" class="" />
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0">{{ count_data.non_fisik_wajar }}</h4>
                                            <p class="card-text font-small-3 mb-0">Sub Kegiatan Wajar</p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>

                            <div class="col-xl-6 col-sm-6 col-12   mb-xl-0">
                                <router-link to="/asb_kewajaran_belanja/detail_dinas/asb">
                                    <div class="media" @click="status_asb('Non Fisik', 'Tidak Wajar')">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <feather-icon icon="GridIcon" size="18" class="" />
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder  text-danger mb-0">
                                                {{ count_data.non_fisik_tidak_wajar }}</h4>
                                            <p class="card-text text-danger  font-small-3 mb-0">Sub Kegiatan Tidak Wajar
                                            </p>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <router-view></router-view>
        </div>

        <TambahSubKegiatan @closebtn=closebtn></TambahSubKegiatan>
    </div>

</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import TambahSubKegiatan from './add_sub_kegiatan/Index.vue'
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import Ripple from 'vue-ripple-directive'
    import {
        BFormSelect,

        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BPagination,
        VBModal
    } from 'bootstrap-vue'

    export default {
        components: {

            BFormSelect,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            BPagination,
            TambahSubKegiatan
        },

        data() {
            return {
                currentPage: 1,
                rows: 0,
                cari: ""
            }
        },
        mixins: [Base],

        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        watch: {
            cari(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
            currentPage(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
        },
        computed: {
            ...mapState({
                rs_dinas: state => state.input.rs_dinas,
                dinas: state => state.input.dinas,
                rs_sub_kegiatan: state => state.input.rs_sub_kegiatan,
                sub_kegiatan: state => state.input.sub_kegiatan,
                count_data: state => state.input.count_data,
                st_asb: state => state.input.st_asb,
                chart_non_fisik: state => state.input.chart_non_fisik,
                chart_fisik: state => state.input.chart_fisik,

            }),
        },
        mounted() {
            this.load_data('', '');
            this.load_data_asb('', '', 'Fisik', 'Wajar');
            this.load_count_asb(); 
            this.set_st_asb('Fisik', 'Wajar'); 
            this.status_asb('Fisik', 'Wajar') 
            this.load();
        },
        methods: {
            ...mapMutations({
                set_rs_dinas: 'set_rs_dinas',
                set_dinas: 'dinas',
                set_rs_sub_kegiatan: "set_rs_sub_kegiatan",
                set_sub_kegiatan: "set_sub_kegiatan",
                set_count_data: 'set_count_data',
                set_st_asb: 'set_st_asb',
                set_chart_non_fisik: 'chart_non_fisik',
                set_chart_fisik: 'set_chart_fisik'
            }),
            closebtn(){

            },
            load() {
                const rs = this.count_data.non_fisik_wajar;

                this.set_chart_fisik([10, 10]);
            },

            // formatPrice(value) {
            //     let val = (value / 1).toFixed(0).replace('.', ',')
            //     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            // },
            detailSubKegiatan(val) {
                this.set_sub_kegiatan(val);
            },
            status_asb(jenis_asb, status_asb) {
                const asb = {
                    jenis_asb: jenis_asb,
                    status_asb: status_asb
                }
                this.set_st_asb(asb);
                // load detail asb
                this.load_data_asb('', '', jenis_asb, status_asb);
                this.load_count_asb();
            },



            async load_data_asb(cari, offset, jenis_asb, status_asb) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/sub_kegiatan/get',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            cari: cari,
                            offset: offset,
                            jenis_asb: jenis_asb,
                            status_asb: status_asb
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_rs_sub_kegiatan(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async load_count_asb() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/sub_kegiatan/count',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_count_data(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },



            async load_data(cari, offset) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/asb_kewajaran_belanja/sub_kegiatan/get',
                        data: {
                            id_master_kode_dinas: self.dinas.id_master_kode_dinas,
                            cari: cari,
                            offset: offset
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.set_rs_sub_kegiatan(response.data.result);
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
          
        }
    }
</script>

<style>

</style>